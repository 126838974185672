/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2021 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import * as Flow from '@flowjs/flow.js';

import { AuthConstant } from '../../constants/auth.constant';
import { SubmitLaterDialogController } from '../orders/dialogs/submitLaterDialog-controller';

//// CONTROLLER ////
export class MakeListController {
    private sessionData: any;

    //Bindable
    public isci: string = '';
    public destination: string = '';
    public spotPanelRef: any;
    public destinationPanelRef: any;
    public uploadPanelRef: any;
    public spotsToAdd: any = [];
    public destinationsToAdd: any = [];
    public spotThumbs: any = [];
    public trafficFile: any|null = null;
    public poNumber: string = '';
    public badPoNumber: Boolean = false;
    public advertiser: string = '';
    public trafficIssues: any;
    public orderType: any = 'TV';
    public availableOrderTypes: any = [];

    public _authConstants: any;
    public spotsToSend: any = [];
    public destinationsToSend: any = [];
    public optionalData: any = {};
    public submitDialogRef: any|null = null;

    // Restrictions needed
    public makelistFilteredOut: Boolean = false;
    public missingDigitalFromDate: Boolean = false;
    public missingDigitalToDate: Boolean = false;

    // A/A/B
    public Agency: any|null = null;
    public searchAgency: any|null = null;
    public Advertiser: any|null = null;
    public searchAdvertiser: any|null = null;
    public Brand: any|null = null;
    public searchBrand: any|null = null;
    public badAgency: Boolean = false;
    public badAdvertiser: Boolean = false;
    public badBrand: Boolean = false;
    public invalidFileFormat: boolean = false;

    // Order Details
    public flightStart: any|null = null;
    public flightEnd: any|null = null;

    static get $inject() {
        return [
            '$mdDialog',
            '$mdPanel',
            '$window',
            'AdvertiserResourceFactory',
            'AgencyResourceFactory',
            'AssetResourceFactory',
            'BrandsResourceFactory',
            'DestinationResourceFactory',
            'EndPointService',
            'EnumService',
            'loginService',
            'NotificationService',
            'OrderResourceFactory',
            'SpotResourceFactory',
            'submitManager',
        ];
    }

    constructor(
        public $mdDialog: any,
        public $mdPanel: any,
        public $window: any,
        public AdvertiserResourceFactory: any,
        public AgencyResourceFactory: any,
        public AssetResourceFactory: any,
        public BrandsResourceFactory: any,
        public DestinationResourceFactory: any,
        public EndPointService: any,
        public EnumService: any,
        public loginService: any,
        public NotificationService: any,
        public OrderResourceFactory: any,
        public SpotResourceFactory: any,
        public submitManager: any,
    ) {
        this._authConstants = new AuthConstant();
        this.sessionData = this.loginService.getSessionData();

        this.EnumService.getEnum('DistributionOrder', 'orderType').then((retrievedOrderTypes:any) => {
            if (retrievedOrderTypes && retrievedOrderTypes.length > 0 && this.sessionData.account) {
                // only add in allowed order types for the logged-in account
                for (let i = 0; i < retrievedOrderTypes.length; i++) {
                    switch (retrievedOrderTypes[i].value) {
                        case 'RADIO':
                            if (this.sessionData.account.allowRadio) {
                                this.availableOrderTypes.push(retrievedOrderTypes[i]);
                            }
                            break;
                        case 'TV':
                            if (!this.sessionData.account.disallowTv) {
                                this.availableOrderTypes.push(retrievedOrderTypes[i]);
                            }
                            break;
                        case 'DIGITAL':
                            this.availableOrderTypes.push(retrievedOrderTypes[i]);
                            break;
                    }
                }
            }
        },
        () => {
            this.availableOrderTypes = [];
        });
    }

    spotEnterPressed(event: any) {
        if(event.keyCode === 13) {
            this.searchIscis();
        }
    }

    destinationEnterPressed(event: any) {
        if(event.keyCode === 13) {
            this.searchDestinations();
        }
    }

    searchIscis() {
        let vm = this;

        if(!vm.isci || vm.isci === '') {
            return 0;
        }

        vm.isci = vm.isci.toUpperCase();

        try {
            vm.SpotResourceFactory.getProviderSpots({ isci: vm.isci, exact: true }).subscribe(
                (results: any) => {
                    let position = vm.$mdPanel.newPanelPosition()
                        .relativeTo('.isci-search-input')
                        .addPanelPosition(vm.$mdPanel.xPosition.ALIGN_START, vm.$mdPanel.yPosition.BELOW);

                    // Identify ISCIs that were not found and can be created
                    let iscis:any = vm.isci.replace(/\s/g, '').split(/\n+|,+|;+/);
                    let foundSpots:any = results.rows;
                    let createIscis:any = [];
                    let createSpots:any = [];

                    // Clear out any existing thumbnails from previous searches
                    vm.spotThumbs = [];

                    for (let i = 0; i < iscis.length; i++) {
                        if(!foundSpots.map((cur:any) => cur.isci).includes(iscis[i].trim()) && iscis[i] !== '') {
                            createIscis.push(iscis[i]);
                        }
                    }

                    createIscis = createIscis.filter((cur:any, index:any, arr:any) => arr.indexOf(cur) === index);

                    for (let i = 0; i < createIscis.length; i++) {
                        createSpots.push({ isci: createIscis[i], selected: false });
                    }

                    // Loop through the existing spots and populate necessary metadata
                    for (var j = 0; j < foundSpots.length; j++) {
                        // Set selected to false, for a default
                        foundSpots[j].selected = true;
                        
                        if(foundSpots[j].ThumbnailAsset && foundSpots[j].ThumbnailAsset.AssetContent && foundSpots[j].ThumbnailAsset.AssetContent.contentUuid) {
                            // Populate the array of image URLs to load in the panel
                            vm._getThumbnail(foundSpots[j].isci, foundSpots[j].ThumbnailAsset.AssetContent.contentUuid);
                        }
                    }

                    let config = {
                        attachTo: angular.element(document.body),
                        controller: (mdPanelRef: any) => {
                            this.spotPanelRef = mdPanelRef;
                        },
                        controllerAs: 'vm',
                        locals: {
                            spots: foundSpots,
                            createSpots,
                            thumbs: vm.spotThumbs,
                            strict: this.sessionData.account.strictIsci,
                            orderType: this.orderType,
                            existing: this._getAlreadyAddedSpots(),
                            filtered: this.makelistFilteredOut,
                            addSpots: async (event: any) => {
                                let createSpots = [...event.new];
                                let oldSpots = [...event.old];
                                
                                // We're done here, close up the panel and start doing the things
                                this.spotPanelRef.close().then(() => {
                                    this.spotPanelRef.destroy();
                                });
                                
                                // Create the new spots in the system
                                let newSpots:any = await this._createSpots(createSpots);

                                // Add all of the spots to the tracking value for display
                                this.spotsToAdd = [...this.spotsToAdd, ...newSpots, ...oldSpots];

                                this.isci = '';
                            },
                            cancelSpots: () => {
                                this.isci = '';

                                // We're done here, close up the panel
                                this.spotPanelRef.close().then(() => {
                                    this.spotPanelRef.destroy();
                                });
                            }
                        },
                        disableParentScroll: false,
                        template: '<cts-quick-send-isci-panel [spots]="vm.spots" [create]="vm.createSpots" [panel]="vm.spotPanelRef" [thumbs]="vm.thumbs" [strict]="vm.strict" [ordertype]="vm.orderType" [existing]="vm.existing" [filtered]="vm.filtered" (added)="vm.addSpots($event)" (canceled)="vm.cancelSpots()"></cts-quick-send-isci-panel>',
                        position: position,
                        hasBackdrop: true,
                        clickOutsideToClose: false,
                        escapeToClose: false,
                        focusOnOpen: true,
                        zIndex: 950
                    };

                    vm.$mdPanel.open(config);
                },
                () => {}
            );
        } catch (e) {
            console.log(e);
        }
    }

    async searchDestinations() {
        let vm = this;

        if(!vm.destination || vm.destination === '') {
            return 0;
        }

        let destination:any = vm.destination.replace(/\s/g, '').split(/\n+|,+|;+/).filter((dest:any) => dest);

        let results = {
            data: {
                count: 0,
                rows: []
            }
        };
        let invalid = false;

        if (destination.length < 1) {
            // The user has entered something that will never work
            invalid = true;
        } else {
            destination = destination.join();
            try {
                results = await vm.DestinationResourceFactory.getAll({ multiCallLetter: destination, inexact: true, limit: 500 }).$promise;
            } catch (e) {
                console.log(e);
            }
        }

        // If we got back less than all of the available destinations for this search, notify the user
        let destinationSearchOverload = false;
        if (results.data.count > results.data.rows.length) {
            destinationSearchOverload = true;
        }

        let position = vm.$mdPanel.newPanelPosition()
            .relativeTo('.destination-search-input')
            .addPanelPosition(vm.$mdPanel.xPosition.ALIGN_START, vm.$mdPanel.yPosition.BELOW);

        // Capture the destinations that were found
        let foundDestinations:any = results.data.rows;

        // Loop through the found destinations and populate necessary metadata
        for (var j = 0; j < foundDestinations.length; j++) {
            // Set selected to false, for a default
            foundDestinations[j].selected = false;
        }

        let config = {
            attachTo: angular.element(document.body),
            controller: (mdPanelRef: any) => {
                this.destinationPanelRef = mdPanelRef;
            },
            controllerAs: 'vm',
            locals: {
                destinations: foundDestinations,
                orderType: this.orderType,
                invalid: invalid,
                overloaded: destinationSearchOverload,
                existing: this._getAlreadyAddedDestinations(),
                addDestinations: async (event: any) => {
                    // Clear out the destination field after adding selected destinations
                    this.destination = '';

                    // Add all of the destinations to the tracking value for display
                    this.destinationsToAdd = [...this.destinationsToAdd, ...event];

                    // We're done here, close up the panel
                    this.destinationPanelRef.close().then(() => {
                        this.destinationPanelRef.destroy();
                    });
                },
                cancelDestinations: () => {
                    // Clear out the destination field after adding selected destinations
                    this.destination = '';

                    // We're done here, close up the panel
                    this.destinationPanelRef.close().then(() => {
                        this.destinationPanelRef.destroy();
                    });
                }
            },
            disableParentScroll: false,
            template: '<cts-quick-send-destination-panel [destinations]="vm.destinations" [panel]="vm.destinationPanelRef" [ordertype]="vm.orderType" [existing]="vm.existing" [invalid]="vm.invalid" [overloaded]="vm.overloaded" (added)="vm.addDestinations($event)" (canceled)="vm.cancelDestinations()"></cts-quick-send-destination-panel>',
            position: position,
            hasBackdrop: true,
            clickOutsideToClose: false,
            escapeToClose: false,
            focusOnOpen: true,
            zIndex: 950
        };

        vm.$mdPanel.open(config);
    }

    loadAgencies(nameSearch:string) {
        return new Promise((resolve, reject) => {
            this.AgencyResourceFactory.getAll(
                {
                    providerAccountId: this.sessionData.accountId,
                    name: nameSearch,
                    activeOnly: true,
                    limit: 15,
                }).subscribe(
                    (agencies:any) => {
                        resolve(agencies.rows);
                    },
                    (err:any) => {
                        resolve([]);
                        console.debug(err);
                    }
                );
        });
    }

    changeAgency() {
        if (
            this.Agency === null ||
            (
                this.Advertiser &&
                this.Advertiser.agencyId !== this.Agency.id
            )
        ) {
            this.Advertiser = null;
            this.changeAdvertiser();
        }
    }

    loadAdvertisers(nameSearch:string) {
        return new Promise((resolve, reject) => {
            let params = {
                providerAccountId: this.sessionData.accountId,
                active: true,
                agencyId: -1,
                name: nameSearch,
                limit: 15,
            };
            if (this.Agency && this.Agency.id !== '') {
                params.agencyId = this.Agency.id;
            }

            this.AdvertiserResourceFactory.getAll(
                params,
            ).subscribe(
                (advertisers:any) => {
                    let found = advertisers;
                    resolve(found);
                },
                (err:any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    changeAdvertiser() {
        // If an advertiser with no ID is selected somehow, just blow it away
        if (this.Advertiser && this.Advertiser.id === '') {
            this.Advertiser = null;
        }

        // If the advertiser has been cleared, or if the brand no longer matches, clear the brand out
        if (
            this.Advertiser === null ||
            (
                this.Brand &&
                this.Brand.advertiserId !== (this.Advertiser && this.Advertiser.id ? this.Advertiser.id : null)
            )
        ) {
            this.Brand = null;
            this.changeBrand();
        }
    }

    loadBrands(nameSearch:string) {
        return new Promise((resolve, reject) => {
            // If there is no advertiser, then there is nothing to do here.
            if (!this.Advertiser || this.Advertiser.id === '') {
                resolve([]);
            }

            // Search by the provider account and search term for sure
            let params = {
                providerAccountId: this.sessionData.accountId,
                advertiserId: this.Advertiser.id,
                name: nameSearch,
                active: true,
                limit: 15,
            };

            this.BrandsResourceFactory.getAll(
                params,
            ).subscribe(
                (brands:any) => {
                    resolve(brands);
                },
                (err:any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    changeBrand() {
        // If a bad brand is selected somehow, clear it out
        if (this.Brand && this.Brand.id === '') {
            this.Brand = null;
        }
    }

    // When a user moves away from an autocomplete, we need to ensure they selected a valid option
    checkValidSelection(type:string) {
        let search = '';
        let desired = {
            name: ''
        };

        switch(type) {
            case 'agency':
                search = this.searchAgency;
                desired = this.Agency;
                break;
            case 'advertiser':
                search = this.searchAdvertiser;
                desired = this.Advertiser;
                break;
            case 'brand':
                search = this.searchBrand;
                desired = this.Brand;
                break;
        }

        // If search was cleared, that's fine, we've nothing to do then
        if(search === '') {
            return;
        }
        
        if(!desired || desired.name !== search) {
            switch(type) {
                case 'agency':
                    this.searchAgency = '';
                    this.Agency = null;
                    break;
                case 'advertiser':
                    this.searchAdvertiser = '';
                    this.Advertiser = null;
                    break;
                case 'brand':
                    this.searchBrand = '';
                    this.Brand = null;
                    break;
            }
        }
    }

    disableClearAdd() {
        return (
            this.spotsToAdd.length < 1 &&
            this.destinationsToAdd.length < 1 &&
            this.trafficFile === null &&
            this.Agency === null &&
            this.Advertiser === null &&
            this.Brand === null &&
            this.poNumber === '' &&
            this.isci === '' &&
            this.destination === '' &&
            this.orderType === 'TV' &&
            this.flightStart === null &&
            this.flightEnd === null
        );
    }

    clearAddPane(preserveOrderType:Boolean) {
        this.spotsToAdd = [];
        this.isci = '';
        this.destinationsToAdd = [];
        this.destination = '';
        this.trafficFile = null;
        this.poNumber = '';
        this.Agency = null;
        this.Advertiser = null;
        this.Brand = null;
        this.flightStart = null;
        this.flightEnd = null;
        this.badAgency = false;
        this.badAdvertiser = false;
        this.badBrand = false;
        this.missingDigitalFromDate = false;
        this.missingDigitalToDate = false;

        if (!preserveOrderType) {
            this.orderType = 'TV';
        }
    }

    removeTraffic() {
        this.trafficFile = null;
    }

    disableClearSend() {
        return (
            this.spotsToSend.length < 1 &&
            this.destinationsToSend.length < 1 &&
            this.optionalData.traffic === undefined &&
            this.optionalData.poNumber === undefined &&
            this.optionalData.agency === undefined &&
            this.optionalData.fullAgency === undefined &&
            this.optionalData.advertiser === undefined &&
            this.optionalData.fullAdvertiser === undefined &&
            this.optionalData.brand === undefined &&
            this.optionalData.fullBrand === undefined
        );
    }

    clearSendPane() {
        this.spotsToSend = [];
        this.destinationsToSend = [];
        this.optionalData = {};
    }

    async addToSend() {
        if (!this.poNumber) {
            this.badPoNumber = true;
        } else {
            this.badPoNumber = false;
        }

        if (!this.Agency) {
            this.badAgency = true;
        } else {
            this.badAgency = false;
        }
        if (!this.Advertiser) {
            this.badAdvertiser = true;
        } else {
            this.badAdvertiser = false;
        }
        if (!this.Brand) {
            this.badBrand = true;
        } else {
            this.badBrand = false;
        }

        if (this.orderType === 'DIGITAL' && !this.flightStart) {
            this.missingDigitalFromDate = true;
        } else {
            this.missingDigitalFromDate = false;
        }

        if (this.orderType === 'DIGITAL' && !this.flightEnd) {
            this.missingDigitalToDate = true;
        } else {
            this.missingDigitalToDate = false;
        }

        if (this.badAgency || this.badAdvertiser || this.badBrand || this.missingDigitalFromDate || this.missingDigitalToDate || this.badPoNumber) {
            // Do not add to send, do NOT collect $200.
            return;
        }

        // Use spread operator to essentially copy and merge instead of pass a reference
        this.spotsToSend = [...this.spotsToSend, ...this.spotsToAdd];
        this.destinationsToSend = [...this.destinationsToSend, ...this.destinationsToAdd];

        this.optionalData = {
            traffic: this.trafficFile,
            poNumber: this.poNumber,
            agency: this.Agency.name,
            fullAgency: this.Agency,
            advertiser: this.Advertiser.name,
            fullAdvertiser: this.Advertiser,
            brand: this.Brand.name,
            fullBrand: this.Brand,
            flightStart: this.flightStart,
            flightEnd: this.flightEnd,
        };

        this.clearAddPane(true);
    }

    async submit(later:Boolean) {
        // Send HTTP request to submit on (traffic upload complete) or (when no traffic is found)
        let order = {
            Agency: this.optionalData.fullAgency,
            Advertiser: this.optionalData.fullAdvertiser,
            Brand: this.optionalData.fullBrand,
            spots: this.spotsToSend,
            destinations: this.destinationsToSend,
            orderGroupTraffic: [] as any,
            poNumber: this.optionalData.poNumber,
            flightStart: this.optionalData.flightStart,
            flightEnd: this.optionalData.flightEnd,
            delayedStartTime: undefined,
            orderType: this.orderType
        };

        let justStop = false;

        if(later) {
            await this.$mdDialog
                .show({
                    controller: SubmitLaterDialogController,
                    controllerAs: 'vm',
                    template: require('../orders/dialogs/submitLaterDialog-template.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    fullscreen: true, // For small screens only
                    locals: {
                        order: order,
                    },
                })
                .then(
                    function (submitLaterTime:any) {
                        // Set the selected time on the order
                        order.delayedStartTime = submitLaterTime;
                    },
                    function () {
                        //user cancelled the popup
                        justStop = true;
                    }
                );
        }

        if(justStop) {
            return false;
        }

        // Show a panel while submit is happening
        let position = this.$mdPanel.newPanelPosition().absolute().center();
        let config = {
            attachTo: angular.element(document.body),
            controller: () => {},
            controllerAs: 'vm',
            locals: {
                title: 'Submitting Your Order',
                conditions: 'your order finishes submitting'
            },
            disableParentScroll: false,
            template: '<cts-waiting-panel [title]="vm.title" [conditions]="vm.conditions"></cts-waiting-panel>',
            position: position,
            hasBackdrop: true,
            clickOutsideToClose: false,
            escapeToClose: false,
            focusOnOpen: true,
            zIndex: 1000
        };
        this.submitDialogRef = await this.$mdPanel.open(config);

        if(this.optionalData.traffic) {
            // Upload the  traffic file before submit
            let uploader = new Flow({
                // target: this.EndPointService.tapUploadEndPoint,
                target: this.EndPointService.uploadTrafficAssetEndpoint,
                headers: () => {
                    let accountHash = JSON.parse(
                        this.$window.localStorage[this._authConstants.session.ACCOUNT_HASH]
                    );
                    let sessionData = JSON.parse(
                        this.$window.sessionStorage[this._authConstants.session.SESSION_DATA]
                    );
    
                    return {
                        Authorization: 'Bearer ' + accountHash[sessionData.accountId],
                    };
                },
                testMethod: false, // Don't check to see if the chunks/files exist first
                testChunks: false, // used in conjunction with above testMethod
                fileParameterName: 'file',
                uploadMethod: 'POST',
                withCredentials: true,
                allowDuplicateUploads: true,
                singleFile: true,
                // 5Gb chunks, should force everything to go at once, since it's limited to that max size
                chunkSize: 5 * 1024 * 1024 * 1024,
                simultaneousUploads: 10,
                query: {
                    ignoreLoadingBar: true,
                    waitForAutoConversions: false,
                },
            });
    
            // Get rid of any pre-existing event handlers
            uploader.off();
    
            // Add the spot file to the uploader
            uploader.addFile(this.optionalData.traffic);
    
            // When a file finishes uploading, grab the assetId and assign it to the spot object
            uploader.on('fileSuccess', (file:any, message:any) => {
                this.optionalData.trafficAssetId = parseInt(message, 10);
            });
    
            // When all files finish uploading
            uploader.on('complete', async () => {
                // Append the newly created traffic assetId
                order.orderGroupTraffic.push({
                    trafficAssetId: this.optionalData.trafficAssetId
                });
                // Actually submit the order now
                this._submitOrder(order);
            });

            // Start the uploads
            uploader.upload();
            
        } else {
            // Submit the order with no traffic asset
            this._submitOrder(order);
        }
    }

    orderHasItems() {
        return (
            this.spotsToAdd.length > 0 ||
            this.destinationsToAdd.length > 0 ||
            this.spotsToSend.length > 0 ||
            this.destinationsToSend.length > 0
        );
    }

    /* Private Methods */
    private async _createSpots(spotList: any[]) {
        if(spotList.length < 1) {
            return [];
        }

        // Show a panel dialog with the spot upload progress for each new spot
        let position = this.$mdPanel.newPanelPosition().absolute().center();
        let config = {
            attachTo: angular.element(document.body),
            controller: (mdPanelRef: any) => {
                this.uploadPanelRef = mdPanelRef;
            },
            controllerAs: 'vm',
            locals: {
                spots: spotList,
                uploadPanelRef: this.uploadPanelRef,
                close: () => {
                    // We're done here, close up the panel
                    this.uploadPanelRef.close().then(() => {
                        this.uploadPanelRef.destroy();
                    });
                }
            },
            disableParentScroll: false,
            template: '<cts-multi-upload-progress-panel [spots]="vm.spots" (close)="vm.close()"></cts-multi-upload-progress-panel>',
            position: position,
            hasBackdrop: true,
            clickOutsideToClose: false,
            escapeToClose: false,
            focusOnOpen: true,
            zIndex: 1001
        };
        this.$mdPanel.open(config);

        let creationPromises = [];
        for(let i = 0; i < spotList.length; i++) {
            if(spotList[i].format === 'HD') {
                spotList[i].hdFlag = true;
            }
            spotList[i].mediaType = spotList[i].format === 'RADIO' ? 'AUDIO' : 'VIDEO';
            spotList[i].downConvertPreference = 'CENTERCUT';

            spotList[i].id = -1;

            spotList[i].agencyId = spotList[i].agency.id;
            spotList[i].advertiserId = spotList[i].advertiser.id;
            spotList[i].brandId = spotList[i].brand.id;

            creationPromises.push(
                this.SpotResourceFactory.create({}, spotList[i])
            )
        }

        // Wait for all of the spots to be created or fail to create
        let spotIds:any = await Promise.allSettled(creationPromises);
        
        // Multi-file Upload Code ====================================================
        let uploader = new Flow({
            target: this.EndPointService.tapUploadEndPoint,
            headers: () => {
                let accountHash = JSON.parse(
                    this.$window.localStorage[this._authConstants.session.ACCOUNT_HASH]
                );
                let sessionData = JSON.parse(
                    this.$window.sessionStorage[this._authConstants.session.SESSION_DATA]
                );

                return {
                    Authorization: 'Bearer ' + accountHash[sessionData.accountId],
                };
            },
            testMethod: false, // Don't check to see if the chunks/files exist first
            testChunks: false, // used in conjunction with above testMethod
            fileParameterName: 'file',
            uploadMethod: 'POST',
            withCredentials: true,
            allowDuplicateUploads: true,
            // 5Gb chunks, should force everything to go at once, since it's limited to that max size
            chunkSize: 5 * 1024 * 1024 * 1024,
            simultaneousUploads: 10,
            query: {
                ignoreLoadingBar: true,
                waitForAutoConversions: false,
            },
        });

        // Get rid of any pre-existing event handlers
        uploader.off();

        uploader.on('fileAdded', (file:any) => {
            file.spotId = file.file.spotId;
        });
        
        for(let x = 0; x < spotIds.length; x++) {
            if(spotIds[x].status === 'fulfilled') {
                // Give the spot its generated ID as well
                spotList[x].id = spotIds[x].value;

                if (spotList[x].asset) {
                    // Append some tracking info to the file before it's added to the upload queue
                    spotList[x].asset.spotId = spotIds[x].value;

                    // Add the spot file to the uploader
                    uploader.addFile(spotList[x].asset);
                } else {
                    spotList[x].uploading = false;
                    spotList[x].progress = 100;
                    spotList[x].success = true;
                }
            }
        }
        uploader.on('fileError', (file:any,message:any) => {
            let fileErrorMessage = message && JSON.parse(message)
                    this.invalidFileFormat = fileErrorMessage && fileErrorMessage.debugMessage == 'Invalid File';
                    if (this.invalidFileFormat) {
                        this.NotificationService.showNotificationToast(
                            'Invalid File',
                            message
                        );
                    }
        });

        uploader.on('fileProgress', (file:any) => {
            // Find and update the spot with its upload progress
            for(let i = 0; i < spotList.length; i++) {
                if(spotList[i].id === file.spotId) {
                    if (file.progress() === 1) {
                        spotList[i].uploading = false;
                        spotList[i].progress = 100;
                    } else {
                        spotList[i].progress = Math.floor(file.progress() * 100);
                    }
                }
            }
        });

        // When a file finishes uploading, grab the assetId and assign it to the spot object
        uploader.on('fileSuccess', function (file:any, message:any) {
            // Find and update the right spot
            for(let i = 0; i < spotList.length; i++) {
                if(spotList[i].id === file.spotId) {
                    spotList[i].assetId = message;
                    spotList[i].success = true;
                }
            }
        });

        let finallyComplete = new Promise((resolve, reject) => {
            // When all files finish uploading
            uploader.on('complete', async () => {
                // Begin MIE Ingest for each spot
                for(let i = 0; i < spotList.length; i++) {
                    if(spotList[i].assetId) {
                        this.SpotResourceFactory.ingestUploadedMediaWithTap({ id: spotList[i].id, assetContentId: spotList[i].assetId }, {}).subscribe(
                            () => {
                                spotList[i].completed = true;
                            },
                            (err:any) => {
                                console.log(err);
                                // Let the user know somehow that their spot ingest failed for this spot
                                spotList[i].completed = true;
                                spotList[i].success = false;
                                spotList[i].failureMessage = 'This spot failed to begin ingest in MIE'
                            }
                        );
                    } else {
                        // Spot has no media to upload, so it is "completed" right away
                        spotList[i].completed = true;
                    }
                }

                resolve(spotList);
            });

            // Start the uploads
            uploader.upload();
        });

        return await finallyComplete;
    }

    private _getThumbnail(isci:string, uuid:string) {
        let vm = this;

        vm.AssetResourceFactory.getImageAsset(
            { uuid },
            {},
            (asset:any) => {
                var blob = new Blob([asset.data], {
                    type: asset.config['Content-Type'],
                });
                vm.spotThumbs[isci] = URL.createObjectURL(blob);
            },
            (err:any) => {
                // Unable to retrieve the image asset for the thumbnail
            }
        );
    };

    private _submitOrder(order: any) {
        this.OrderResourceFactory.submitMakelist(
            {},
            { orders: [order] }, // Keeping orders an array, in case that is useful someday
            (message:any) => {
                this.clearSendPane();
                this.submitDialogRef.close().then(() => {
                    this.submitDialogRef.destroy();
                });
                this.NotificationService.showNotificationToast(message.data);
            },
            (err:any) => {
                console.log(err);
                this.submitDialogRef.close().then(() => {
                    this.submitDialogRef.destroy();
                });
                this.NotificationService.showNotificationToast(err.data);
            }
        );
    }

    private _getAlreadyAddedSpots() {
        let alreadyAdded = [
            ...this.spotsToAdd.map((cur:any) => {
                return cur.id
            }),
            ...this.spotsToSend.map((cur:any) => {
                return cur.id
            }),
        ];
        return alreadyAdded;
    }

    private _getAlreadyAddedDestinations() {
        let alreadyAdded = [
            ...this.destinationsToAdd.map((cur:any) => {
                return cur.id
            }),
            ...this.destinationsToSend.map((cur:any) => {
                return cur.id
            }),
        ];
        return alreadyAdded;
    }
}
